import clsx from 'clsx';
import { CSSProperties, InputHTMLAttributes, RefObject } from 'react';

export type Props = InputHTMLAttributes<HTMLInputElement> & {
  className?: string;
  customRef?: RefObject<HTMLInputElement>;
  style?: CSSProperties;
};

export function Input({ className, customRef, ...props }: Props) {
  return (
    <input
      ref={customRef}
      className={clsx('form__input', className)}
      {...props}
    />
  );
}

export function TextInput({ className, ...props }: Props) {
  return (
    <input type="text" className={clsx('form__input', className)} {...props} />
  );
}
