import type {
  ChangeEvent,
  CSSProperties,
  HTMLInputTypeAttribute,
  KeyboardEvent,
  ReactNode,
  RefObject
} from 'react';
import { Input } from '~/components/atoms/input';
import { FlexColumn } from '~/components/atoms/container';

export type FormFieldProps = {
  name: string;
  label?: string;
  type?: HTMLInputTypeAttribute;
  style?: CSSProperties;
  defaultValue?: string;
  readOnly?: boolean;
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
  required?: boolean;
  placeholder?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onKeyUp?: (e: KeyboardEvent<HTMLInputElement>) => void;
  customRef?: RefObject<HTMLInputElement>;
};

export function FormField({
  label,
  name,
  type = 'text',
  style,
  defaultValue,
  readOnly,
  children,
  className,
  disabled,
  required,
  placeholder,
  onChange,
  onBlur,
  onKeyDown,
  onKeyUp,
  customRef
}: FormFieldProps) {
  return (
    <FlexColumn style={style}>
      {label ? (
        <label
          htmlFor={name}
          children={label}
          className="type__body2 type__body2--lc"
          style={{ color: '#161938' }}
        />
      ) : null}
      <Input
        type={type}
        id={name}
        name={name}
        defaultValue={defaultValue}
        readOnly={readOnly}
        style={{ marginTop: label ? 12 : 0 }}
        className={className}
        disabled={disabled}
        required={required}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        customRef={customRef}
      />
      {children}
    </FlexColumn>
  );
}
